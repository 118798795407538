import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import { Card, Button, CardBody, Row, Col, Input } from "reactstrap";
import { v4 as uuid } from "uuid";
import ApiContext from "../ApiContext";
import { swal } from "../Swal";

function Question() {
	const { id } = useParams();
	const token = useContext(ApiContext);
	const [question, setQuestion] = useState([]);
	const [answers, setAnswers] = useState([]);

	useEffect(() => {
		const loadAnswers = async () => {
			var res = await fetch(
				`https://surveysays.myactivityhub.net/api.php?questionAnswers=${id}`,
				{
					headers: new Headers({
						Authorization: token,
					}),
				}
			);
			res = await res.json();
			setAnswers(res?.answers);
			setQuestion(res.question);
		};
		loadAnswers();
	}, [id, token]);

	const plusHandler = () => {
		setAnswers([
			...answers,
			{
				answer_id: uuid(),
				answer_popularity: 0,
				answer_text: "",
				answer_new: 1,
			},
		]);
	};

	const updateAnswer = (index, target, data) => {
		const newAnswers = [...answers];
		newAnswers[index][target] = data;
		setAnswers(newAnswers);
	};

	const saveAnswers = async () => {
		var res = await fetch(
			`https://surveysays.myactivityhub.net/api.php?saveQuestionAnswers=${question.question_id}`,
			{
				method: "POST",
				headers: new Headers({
					Authorization: token,
					"Content-Type": "application/json",
				}),
				body: JSON.stringify(answers),
			}
		);
		if (res.ok) swal({ title: "Answers Saved" });
	};

	return (
		<>
			<div>
				<h3 className="mt-5">
					{question.question_text}
					<i
						style={{ fontSize: "2rem", cursor: "pointer" }}
						className="fa fa-plus-circle float-right"
						onClick={plusHandler}
					></i>
				</h3>
				{answers.map((a, index) => {
					return (
						<QuestionAnswerInput
							key={a.answer_id}
							updateAnswer={updateAnswer}
							setAnswers={setAnswers}
							answers={answers}
							index={index}
							deleted={a.deleted}
							answer_id={a.answer_id}
							answer={a.answer_text}
							popularity={a.answer_popularity}
						/>
					);
				})}
				<Button onClick={saveAnswers} className="btn-block">
					Save
				</Button>
			</div>
		</>
	);
}

// Answer Input
const QuestionAnswerInput = (props) => {
	// const { answer_id, answer, popularity, index, updateAnswer, setAnswers, answers, deleted } = props
	const { answer, popularity, index, updateAnswer, deleted } = props;

	if (deleted) return false;

	return (
		<div>
			<Card color="dark" className="mb-1">
				<CardBody>
					<Row>
						<Col md="9">
							<Input
								value={answer}
								onChange={(e) =>
									updateAnswer(index, "answer_text", e.target.value)
								}
							/>
						</Col>
						<Col md="2">
							<Input
								value={popularity}
								onChange={(e) =>
									updateAnswer(index, "answer_popularity", e.target.value)
								}
							/>
						</Col>
						<Col md="1">
							<i
								onClick={() => {
									updateAnswer(index, "deleted", true);
								}}
								style={{ fontSize: "1.3em", cursor: "pointer" }}
								className="fa fa-trash"
							></i>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</div>
	);
};

export default Question;
