import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const swal = async ({ title = '', icon = 'success', text = '' }) => {
	await MySwal.fire(
		title,
		text,
		icon
	)
}

export const swalConfirm = async ({ title = 'Are you sure?', icon = 'question', text = '', type = 'text' }) => {
	return await MySwal.fire({
		title: title,
		text: text,
		icon: icon,
		type: type,
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: 'Yes'
	}).then((result) => {
		if (result.isConfirmed) {
			return true
		}
	})
}


export const swalText = async ({ title = 'Please enter text?', input = 'text', label = '' }) => {
	return await MySwal.fire({
		title: title,
		input,
		inputLabel: label,
		showCancelButton: true,
		inputValidator: (value) => {
			if (!value) {
				return 'You need to write something!'
			}
		}
	})
}
