import React, { useState } from 'react'
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const PlayersModal = (props) => {
	// const { team_id, set_team_id, toggle, request, event, setEvent } = props
	const { team_id, set_team_id, request } = props

	const [players, setPlayers] = useState()
	function save() {
		request('addPlayers', {
			team_id,
			players
		})
		// var players = players.split("\n")
		// event.teams.find(team => team.team_id == team_id)['players'].push("test")
	}
	function close() {
		set_team_id(false)
	}

	return (
		<div>
			<Modal isOpen={team_id} toggle={close} >
				<ModalHeader toggle={close}>Add Players</ModalHeader>
				<ModalBody>
					<Input placeholder='Player 1' type="textarea" name="text" id="exampleText" onChange={(e) => setPlayers(e.target.value)} value={players} />
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={save}>Add Players</Button>
					<Button color="secondary" onClick={close}>Cancel</Button>
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default PlayersModal
