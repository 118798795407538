import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Table, Button } from "reactstrap";
import Swal from "sweetalert2";
import ApiContext from "./ApiContext";
import { swal } from "./Swal";

const Summary = () => {
	const { id } = useParams();
	const [data, setData] = useState(false);
	const token = useContext(ApiContext);

	// Load Questions from DB
	const load = useCallback(async (body = null) => {
		var res = await fetch(
			`https://surveysays.myactivityhub.net/api.php?summary=${id}`,
			{
				headers: new Headers({
					Authorization: token,
				}),
				body: JSON.stringify(body),
				method: "POST",
			}
		);
		res = await res.json();
		setData(res);
	});

	useEffect(() => {
		load();
	}, []);

	const setPresenters = async () => {
		let list = data.presenters?.join("\n");
		const { value: text } = await Swal.fire({
			input: "textarea",
			inputLabel: "List of presenters",
			inputValue: list,
			inputPlaceholder: "Tim\nBob\nRichard...",
			inputAttributes: {
				"aria-label": "Type your message here",
			},
			showCancelButton: true,
		});
		if (text === undefined) return;

		await load({ setSummaryPresenters: text });
	};

	if (!data) return <h2>Loading...</h2>;
	return (
		<>
			<Link to={"/event/" + id}>
				<Button className="float-right">
					<i className="fa fa-arrow-left"></i> Return
				</Button>
			</Link>
			<h1>Event Summary</h1>
			<hr />
			<Table className="text-white text-center" bordered={true}>
				<thead>
					<tr>
						<td>
							<Button color="primary" onClick={setPresenters}>
								Set Presenters
							</Button>
						</td>
						{data.rounds[0].map((a, i) => (
							<td>
								{data.presenters[i] || <b style={{ color: "red" }}>MISSING</b>}
							</td>
						))}
					</tr>
				</thead>
				<tbody>
					{data.rounds.map((round) => (
						<>
							<tr>
								<td rowSpan="2">Round&nbsp;{round[0].round_number}</td>
								{round.map((game) => (
									<td style={{ backgroundColor: game.teams[0].team_colour }}>
										<b>{game.teams[0].team_name}</b>
									</td>
								))}
							</tr>
							<tr>
								{round.map((game) => (
									<td style={{ backgroundColor: game.teams[1].team_colour }}>
										<b>{game.teams[1].team_name}</b>
									</td>
								))}
							</tr>
							<tr>
								<td colspan="4"></td>
							</tr>
						</>
					))}
				</tbody>
			</Table>

			{data.rounds.map((round) => (
				<>
					<hr />
					<h2>Round&nbsp;{round[0].round_number}</h2>
					{round.map((game, i) => (
						<>
							{data.presenters[i] || <b style={{ color: "red" }}>MISSING</b>}
							&nbsp;:&nbsp;
							<a
								href={"http://www.surveysays.online/game?g=" + game.game_hash}
								target="_blank"
							>
								{"http://www.surveysays.online/game?g=" + game.game_hash}
							</a>
							<br />
						</>
					))}
				</>
			))}
			{data.final && (
				<>
					<hr />
					<h2>Final Round</h2>
					Link :{" "}
					<a
						href={
							"http://www.surveysays.online/final?g=" + data.final.game_hash
						}
						target="_blank"
					>
						{"http://www.surveysays.online/final?g=" + data.final.game_hash}
					</a>
					<hr />
				</>
			)}
			{/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
		</>
	);
};

export default Summary;
