import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
	DropdownItem,
	Input,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	Badge,
	Button,
} from "reactstrap";
import ApiContext from "./ApiContext";

const Rounds = () => {
	const { id } = useParams();
	const [data, setData] = useState(false);
	const [filter, setFilter] = useState("");
	const token = useContext(ApiContext);

	useEffect(() => {
		// Load Questions from DB
		const load = async () => {
			var res = await fetch(
				`https://surveysays.myactivityhub.net/api.php?rounds=${id}`,
				{
					headers: new Headers({
						Authorization: token,
					}),
				}
			);
			res = await res.json();
			setData(res);
		};
		load();
	}, [id, token]);

	async function request(func, data) {
		var res = await fetch(
			`https://surveysays.myactivityhub.net/api.php?rounds=${id}&${func}`,
			{
				method: "POST",
				headers: new Headers({
					Authorization: token,
					"Content-Type": "application/json",
				}),
				body: JSON.stringify(data),
			}
		);
		res = await res.json();
		setData(res);
	}

	useEffect(() => {
		if (data) document.getElementById("filter").focus();
	}, [data]);

	if (!data) return <h2>Loading...</h2>;
	return (
		<>
			<Link to={"/event/" + id}>
				<Button className="float-right">
					<i className="fa fa-arrow-left"></i> Return
				</Button>
			</Link>
			<h1>Rounds</h1>
			<hr />
			<Input
				id="filter"
				onChange={(e) => setFilter(e.target.value)}
				className="mb-1"
				placeholder="Search ..."
			/>
			{data.map((q) => {
				if (
					!q.question_text.toLowerCase().includes(filter.toLowerCase()) &&
					!q.round_number
				)
					return null;
				return (
					<div key={q.question_id} className="mb-2">
						{q.question_text}
						{q.round_number && (
							<Badge color="light" className="ml-1">
								<b> ROUND {q.round_number}</b>
							</Badge>
						)}
						{q.final_team_number && (
							<Badge color="warning" className="ml-1">
								<b>
									{" "}
									FINAL QUESTION{" "}
									<Badge color={q.final_team_number === 1 ? "info" : "dark"}>
										TEAM {q.final_team_number}
									</Badge>
								</b>
							</Badge>
						)}
						<RoundsDropdown
							question_id={q.question_id}
							request={request}
							has_round={q.round_number}
							has_final={q.final_team_number}
						/>
					</div>
				);
			})}
			{/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
		</>
	);
};

export default Rounds;

const RoundsDropdown = (props) => {
	var { question_id, request, has_round, has_final } = props;
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(!dropdownOpen);

	return (
		<Dropdown
			isOpen={dropdownOpen}
			toggle={toggle}
			style={{ display: "inline" }}
		>
			<DropdownToggle
				tag="span"
				onClick={toggle}
				size="sm"
				caret
			></DropdownToggle>
			<DropdownMenu>
				{has_round && (
					<DropdownItem
						onClick={() => request("changeRound", { round: 0, question_id })}
					>
						Remove Round
					</DropdownItem>
				)}
				{[1, 2, 3, 4, 5, 6, 7, 8].map((r) => {
					return (
						<DropdownItem
							key={question_id + r}
							onClick={() => request("changeRound", { round: r, question_id })}
						>
							Round {r}
						</DropdownItem>
					);
				})}
				<DropdownItem divider />
				{has_final && (
					<DropdownItem
						onClick={() =>
							request("removeFinal", { question_id, team: has_final })
						}
					>
						Remove Final Round
					</DropdownItem>
				)}
				<DropdownItem
					onClick={() => request("setFinal", { question_id, team: 1 })}
				>
					Final Round <Badge color="info">Team 1</Badge>
				</DropdownItem>
				<DropdownItem
					onClick={() => request("setFinal", { question_id, team: 2 })}
				>
					Final Round <Badge color="dark">Team 2</Badge>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};
