import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router";
import { Button, Row, Col } from "reactstrap";
import ApiContext from "../ApiContext";
import Socket from "../../context/Socket";

const Presenter = () => {
	const token = useContext(ApiContext);
	const [data, setData] = useState(false);
	const [roomId, setRoomId] = useState();

	const active_team = data.teams?.find(
		(team) => team.team_id === data.active_team_id
	);

	const showQuestion = async () => {
		setData({ ...data, question_revealed: true });
		await loadData({ setQuestionRevealed: true });
		Socket.emit("show_question");
	};

	const setPlayingTeam = async (team, silent = false) => {
		setData({ ...data, active_team_id: team });
		await loadData({ setActiveTeam: team });
		let name = data.teams.find((t) => t.team_id === team).team_name;
		if (!silent) Socket.emit("active_team", { name });
		else Socket.emit("refresh");
	};

	const resetGame = async () => {
		setData(false);
		await loadData({ resetGame: true });
		Socket.emit("game_reset");
	};

	const usedAnswerCount = () => {
		let count = 0;
		data.answers.map((a) => {
			if (a.used) count++;
			return false;
		});
		return count;
	};

	const flipTeam = (silent = false) => {
		if (!active_team) return;
		if (!silent) Socket.emit("switching_teams");
		for (let i = 0; i < data.teams.length; i++) {
			if (data.teams[i].team_id === active_team.team_id) continue;
			setPlayingTeam(data.teams[i].team_id, silent);
			return data.teams[i].team_name;
		}
	};

	const bothTeams3Wrongs = () => {
		for (let i = 0; i < data.teams.length; i++) {
			if (data.teams[i].wrong_count < 3) return false;
		}
		return true;
	};

	const finishGame = async (winning_team_name = active_team.team_name) => {
		setData({ ...data, game_finished: true });
		await loadData({ setFinished: true });
		Socket.emit("pow_text", { text: "Game Finished!" });
		if (data.score_pot)
			Socket.emit("pow_text", {
				text: winning_team_name + " are the winners!",
			});
		else Socket.emit("pow_text", { text: "Draw, there are no winners!" });
	};

	const wrongs = async (team, delta) => {
		let flip = false,
			finish = false;
		let new_teams = data.teams.map((t) => {
			if (t.team_id !== team) return t;
			t.wrong_count += delta;
			if (delta > 0 && t.wrong_count === 3) flip = true;
			if (delta > 0 && usedAnswerCount() === 0) flip = true;
			if (bothTeams3Wrongs()) finish = true;
			return t;
		});
		setData({ ...data, teams: new_teams });
		await loadData({ setWrongteam: team, setWrongDelta: delta });
		if (delta > 0) Socket.emit("wrong_answer");
		else Socket.emit("refresh");

		let winning_team_name = active_team.team_name; // Incase end of game
		if (finish) {
			if (flip) winning_team_name = flipTeam(true);
			finishGame(winning_team_name);
			return;
		}

		if (flip) flipTeam();
	};

	const payout = async () => {
		setData({ ...data, score_pot: 0 });
		await loadData({ payout: data.score_pot, team: active_team.team_id });
		Socket.emit("payout", {
			pot: data.score_pot,
			team_id: active_team.team_id,
			team_score: active_team.team_score + data.score_pot,
		});
	};

	const chooseAnswer = async (answer_id, show, points) => {
		let new_answers = data.answers.map((a, i) => {
			if (a.answer_id == answer_id) data.answers[i].used = show;
			return a;
		});
		setData({
			...data,
			answers: new_answers,
			score_pot: data.score_pot + points,
		});
		await loadData({ revealAnswer: answer_id, used: show, points: points });
		Socket.emit("answer", { show });

		if (points <= 0 || data.answers.filter((a) => !a.used).length > 0) return;
		finishGame();
	};

	const { id } = useParams();

	console.log();

	const loadData = useCallback(
		async (body = {}) => {
			var res = await fetch(
				`https://surveysays.myactivityhub.net/api.php?presenter=${id}`,
				{
					method: "POST",
					headers: new Headers({
						Authorization: token,
					}),
					body: JSON.stringify(body),
				}
			);
			res = await res.json();
			setRoomId(`game-${res.game_hash}`);
			setData(res);
		},
		[token, id]
	);

	useEffect(() => {
		loadData();
	}, [loadData, id]);

	useEffect(() => {
		if (!roomId) return; // No room to join yet
		Socket.joinRoom(roomId);
		return () => Socket.joinRoom("join_room", `none`);
	}, [roomId]);

	if (!data) return <h3>Loading ...</h3>;

	const WhoFirstQuestion = () => {
		return (
			!active_team &&
			data.question_revealed && (
				<>
					<h1>Which team first?</h1>
					<Row>
						{data.teams.map((t) => {
							return (
								<Col key={t.team_id} md="6">
									<Button
										color="outline-warning"
										onClick={() => setPlayingTeam(t.team_id)}
										style={{ backgroundColor: t.team_color }}
									>
										<Team t={t} />
									</Button>
								</Col>
							);
						})}
					</Row>
				</>
			)
		);
	};

	const ShowQuestionBtn = () => {
		return (
			!data.question_revealed && (
				<div>
					<Button
						color="outline-warning"
						onClick={showQuestion}
						className="btn-lg"
					>
						<i className="fa fa-arrow-right" /> Reveal Question
					</Button>
				</div>
			)
		);
	};

	const GameFinished = () => {
		let remaining = data.answers.filter((a) => !a.used);
		return (
			data.game_finished && (
				<>
					<h1>Game Finished!</h1>
					{!!data.score_pot && (
						<h2>
							The winners are <Team t={active_team} />
						</h2>
					)}
					{data.score_pot === 0 && <h2>The game was a draw</h2>}
					{remaining.length > 0 && <h2>Remaining Answers:</h2>}
					{remaining.map((a) => {
						return (
							<p key={a.answer_id}>
								<Button
									size="sm"
									onClick={() => chooseAnswer(a.answer_id, true, 0)}
									color="outline-warning"
								>
									<i className="fa fa-check" /> Reveal
								</Button>{" "}
								{a.answer_text}{" "}
							</p>
						);
					})}
					{data.score_pot > 0 && (
						<Button color="warning" onClick={payout}>
							{" "}
							Payout Reward! (Team {active_team.team_name}){" "}
						</Button>
					)}
					{data.score_pot == 0 && <>(No score to payout)</>}
				</>
			)
		);
	};

	const Team = ({ t }) => {
		let style = {
			backgroundColor: t.team_colour,
			borderRadius: "10px",
			padding: "0px 10px",
		};
		return <b style={style}>{t.team_name}</b>;
	};

	const Summary = ({ data }) => {
		return (
			<div
				style={{
					border: "1px solid yellow",
					padding: "5px 20px",
					borderRadius: "16px",
					background: "#220",
				}}
			>
				<Button onClick={flipTeam} color="warning" className="float-left">
					SWITCH TEAMS
				</Button>
				<h2 className="text-right">Score: {data.score_pot}</h2>
				{/* <i className='fa fa-hand-holding-usd fa-fw' /> */}
				<h2> Team Members </h2>
				{data.teams.map((t) => {
					return (
						<div key={t.team_id}>
							<Team t={t} /> Score: <b>{t.team_score}</b>
							<ul>
								{t.players.map((p) => {
									return <li key={p.person_id}>{p.person_name}</li>;
								})}
							</ul>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<Row>
			<Col md="12">
				<h2>
					{data.question_text}
					<Button onClick={resetGame} color="danger" className="float-right">
						RESET GAME
					</Button>
				</h2>
				<hr />
			</Col>

			{!data.game_finished && (
				<>
					<Col md="4">
						<ShowQuestionBtn />
						<WhoFirstQuestion />

						{active_team && (
							<>
								<h4>
									Team <Team t={active_team} /> is playing
								</h4>
								{data.answers.map((a) => {
									if (a.used)
										return (
											<p key={a.answer_id}>
												<Button
													color="outline-secondary"
													onClick={() =>
														chooseAnswer(
															a.answer_id,
															false,
															-a.answer_popularity
														)
													}
												>
													<i className="fa fa-times" /> Hide
												</Button>{" "}
												{a.answer_text}{" "}
											</p>
										);
									else
										return (
											<p key={a.answer_id}>
												<Button
													color="outline-success"
													onClick={() =>
														chooseAnswer(a.answer_id, true, a.answer_popularity)
													}
												>
													<i className="fa fa-check" /> Choose
												</Button>{" "}
												{a.answer_text}{" "}
											</p>
										);
								})}
							</>
						)}
					</Col>

					<Col md="4">
						{active_team && (
							<>
								<div className="text-center">
									<i
										style={{ fontSize: "2em" }}
										className={
											active_team.wrong_count >= 1
												? "fa fa-times-circle mx-3"
												: "far fa-circle mx-3"
										}
									></i>
									<i
										style={{ fontSize: "2em" }}
										className={
											active_team.wrong_count >= 2
												? "fa fa-times-circle mx-3"
												: "far fa-circle mx-3"
										}
									></i>
									<i
										style={{ fontSize: "2em" }}
										className={
											active_team.wrong_count >= 3
												? "fa fa-times-circle mx-3"
												: "far fa-circle mx-3"
										}
									></i>
								</div>
								<Button
									onClick={() => wrongs(active_team.team_id, 1)}
									className="btn-block mt-2"
								>
									Add Cross (Wrong Answer)
								</Button>
								<Button
									onClick={() => wrongs(active_team.team_id, -1)}
									className="btn-sm btn-block"
								>
									Remove Cross (Undo Wrong)
								</Button>
								<Button
									onClick={() => {
										finishGame();
									}}
									className="btn-sm btn-block"
								>
									Finish Game
								</Button>
							</>
						)}
					</Col>
				</>
			)}

			{data.game_finished && (
				<Col md="8">
					<GameFinished />
				</Col>
			)}

			<Col md="4">
				<Summary data={data} />
			</Col>
			{/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
		</Row>
	);
};

export default Presenter;
