import { useEffect, useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem, Button } from "reactstrap";
// import { useSocket } from "../context/Socket";
import Login from "./login/Login";
import useToken from "./login/useToken";
import { swal, swalConfirm, swalText } from "./Swal";

const Home = () => {
	const { token, setToken } = useToken();
	const [events, setEvents] = useState();

	const loadEvents = useCallback(async () => {
		var res = await fetch(
			"https://surveysays.myactivityhub.net/api.php?upcomingEvents",
			{
				headers: new Headers({
					Authorization: token,
				}),
			}
		);
		res = await res.json();
		setEvents(res);
	}, [token]);

	useEffect(() => {
		loadEvents();
	}, [loadEvents]);

	if (!token) return <Login setToken={setToken} />;

	const archiveEvent = async (event_id) => {
		var res = await fetch(
			`https://surveysays.myactivityhub.net/api.php?archiveEvent=${event_id}`,
			{
				headers: new Headers({
					Authorization: token,
				}),
			}
		);
		if (res.ok) {
			loadEvents();
			swal({ title: "Event archived" });
		}
	};

	const addEvent = async () => {
		var event_name = await swalText({ title: "Whats the event name?" });
		if (!event_name.value) return;
		var res = await fetch(
			`https://surveysays.myactivityhub.net/api.php?createEvent`,
			{
				headers: new Headers({
					Authorization: token,
				}),
				method: "POST",
				body: JSON.stringify({ event_name: event_name.value }),
			}
		);
		if (res.ok) {
			loadEvents();
			swal({ title: "Event Created" });
		}
	};

	if (!events) return <h1>Loading Events...</h1>;
	else
		return (
			<>
				<h2 className="my-4">
					<i className="far fa-calendar-alt mr-1"></i>
					Upcoming Events
					<i
						onClick={addEvent}
						style={{ cursor: "pointer" }}
						className="fa fa-plus-circle float-right mr-4 mt-2"
					></i>
				</h2>
				<ListGroup>
					{events.map((e) => {
						return (
							<ListGroupItem key={e.event_id}>
								<Link to={`/event/${e.event_id}`}>{e.event_name}</Link>
								<span style={{ float: "right" }}>
									Created {e.event_created}
									&nbsp;
									<Button
										onClick={async () => {
											if (
												await swalConfirm({ text: `Archive ${e.event_name}?` })
											)
												archiveEvent(e.event_id);
										}}
										size="sm"
									>
										<i className="fa fa-archive"></i>
									</Button>
								</span>
							</ListGroupItem>
						);
					})}
				</ListGroup>
			</>
		);
};

export default Home;
