import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import {
	Card,
	Button,
	CardHeader,
	CardBody,
	Row,
	Col,
	Table,
	Badge,
	Input,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import ApiContext from "../ApiContext";

import PlayersModal from "./PlayersModal";

import { swalConfirm, swalText } from "../Swal";

const Event = () => {
	const { id } = useParams();
	const token = useContext(ApiContext);

	async function removeFromTeam(person_id) {
		if (!(await swalConfirm({ text: "Remove User" }))) return;
		request("removeTeamPerson", {
			person_id,
		});
	}

	function movePersonTeam(person_id, team_id) {
		request("movePersonTeam", {
			person_id,
			team_id,
		});
	}

	async function renamePlayer(person_id) {
		var name =
			(await (await swalText({ title: "Please enter players name" })).value) ??
			null;
		if (name) {
			request("renamePlayer", {
				person_id,
				name,
			});
		}
	}
	async function renameTeam(team_id) {
		var name =
			(await (await swalText({ title: "Please enter teams name" })).value) ??
			null;
		if (name) {
			request("renameTeam", {
				team_id,
				name,
			});
		}
	}

	async function deleteGame(game_id) {
		if (!(await swalConfirm({ text: "Delete Game" }))) return;
		request("deleteGame", {
			game_id,
		});
	}

	function createGameFromSelection() {
		request("createGame", { teams: checkedTeams });
		setCheckedTeams([]);
		var checkboxes = document.getElementsByClassName("form-check-input");
		for (let i = 0; i < checkboxes.length; i++) {
			const c = checkboxes[i];
			c.checked = false;
		}
	}

	async function createTeam() {
		var name =
			(await (await swalText({ title: "Please enter the team name" })).value) ??
			null;
		if (name) {
			request("createTeam", {
				name,
			});
		}
	}

	async function setupGame() {
		if (event.teams.length == 1) {
			var teams =
				(await (
					await swalText({
						title: "Please enter the amount of teams",
						type: "number",
					})
				).value) ?? null;
			if (!teams) return;
		}

		request("setupGame", { teams });
	}

	const [event, setEvent] = useState(null);
	useEffect(() => {
		const loadEvent = async () => {
			var res = await fetch(
				`https://surveysays.myactivityhub.net/api.php?event=${id}`,
				{
					headers: new Headers({
						Authorization: token,
					}),
				}
			);
			res = await res.json();
			setEvent(res);
		};
		loadEvent();
	}, [id, token]);

	async function request(func, data) {
		var res = await fetch(
			`https://surveysays.myactivityhub.net/api.php?event=${id}&${func}`,
			{
				method: "POST",
				headers: new Headers({
					Authorization: token,
					"Content-Type": "application/json",
				}),
				body: JSON.stringify(data),
			}
		);
		res = await res.json();
		setEvent(res);
	}

	const [playerModalTeam, setPlayerModalTeam] = useState(false);
	const [checkedTeams, setCheckedTeams] = useState([]);

	function handleTeamCheck(e, team_id) {
		var checked = e.target.checked;
		if (checked) {
			setCheckedTeams([...checkedTeams, team_id]);
		} else
			setCheckedTeams(
				checkedTeams.filter((t) => {
					if (t !== team_id) return t;
					else return false;
				})
			);
	}

	if (!event) return <h1>Loading Event...</h1>;
	return (
		<>
			<Link to="/">
				<Button className="float-right">
					<i className="fa fa-arrow-left"></i> Return
				</Button>
			</Link>
			<Link to={"/custom/" + id}>
				<Button className="float-right mr-1">
					<i className="fa fa-paint-brush"></i> Customize
				</Button>
			</Link>
			<h2>{event.event_name}</h2>
			<hr />
			<Row>
				<Col md="6">
					<Card color="dark">
						<CardHeader tag="h5">
							Teams
							<Button onClick={setupGame} disabled className="float-right">
								<i class="fa fa-magic"></i> Setup Game
							</Button>
						</CardHeader>
						<CardBody>
							<Table dark={true} striped={true}>
								<tbody>
									{event.teams.map((t) => {
										return (
											<tr key={t.team_id}>
												<td>
													{t.team_id !== 0 && (
														<>
															<Input
																onChange={(e) => {
																	handleTeamCheck(e, t.team_id);
																}}
																type="checkbox"
																className="ml-0"
															></Input>
															&nbsp;&nbsp;&nbsp;&nbsp;
															<i
																style={{ color: t.team_colour }}
																className="fa fa-circle"
															></i>
														</>
													)}
													<span className="ml-1">{t.team_name}</span>
													{t.team_id !== 0 && (
														<TeamDropdown
															team_id={t.team_id}
															request={request}
															renameTeam={renameTeam}
														/>
													)}
												</td>
												<td>{t.team_score}</td>
												<td>
													{t.team_id !== 0 && (
														<>
															<i
																onClick={() => setPlayerModalTeam(t.team_id)}
																className="fa fa-plus"
															></i>{" "}
															Add Players
															<br />
														</>
													)}
													{t.players.map((p) => {
														return (
															<div key={p.person_id}>
																<div>
																	{p.person_name}
																	{
																		<TeamPlayerDropdown
																			person_id={p.person_id}
																			teams={event.teams}
																			movePersonTeam={movePersonTeam}
																			renamePlayer={renamePlayer}
																		/>
																	}
																</div>
																{/* {t.team_id !== 0 && <i onClick={() => removeFromTeam(p.person_id)} className='fa fa-times float-right'></i>} */}
															</div>
														);
													})}
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
							<Button onClick={createTeam} color="primary">
								Create New Team
							</Button>{" "}
							&nbsp;
							<Button onClick={createGameFromSelection} color="info">
								Create Game From Selection
							</Button>
						</CardBody>
					</Card>
				</Col>

				<Col md="6">
					<Card color="dark">
						<CardHeader tag="h5">
							Games
							<Link to={"/rounds/" + id}>
								<Button className="float-right">
									<i class="fa fa-sliders-h"></i> Configure Rounds
								</Button>
							</Link>
							<Link to={"/summary/" + id}>
								<Button className="ml-4">
									<i class="fa fa-check"></i> Summary
								</Button>
							</Link>
						</CardHeader>
						<CardBody>
							<Table dark={true} striped={true}>
								<tbody>
									{event.games.map((g) => {
										var rowStyle =
											g.final_round === 1 ? { backgroundColor: "#485ac1" } : {};
										return (
											<tr key={g.game_id} style={rowStyle}>
												<td>
													{g.round_number > 0 && (
														<>
															<span>Round {g.round_number}</span>
															<RoundDropdown
																request={request}
																game_id={g.game_id}
															/>
														</>
													)}
													{!g.round_number && !g.final_round && (
														<>
															<Badge color="danger">Round&nbsp;0</Badge>
															<RoundDropdown
																request={request}
																game_id={g.game_id}
															/>
														</>
													)}
													{g.final_round && (
														<Badge color="primary">Final Round</Badge>
													)}
													<br />
													ID {g.game_id}
												</td>
												<td>
													{g.teams &&
														g.teams.map((t) => {
															return (
																<Badge
																	key={t.team_id + "badge"}
																	style={{
																		backgroundColor: "white",
																		color: "black",
																	}}
																	className="mx-1"
																>
																	<i
																		style={{ color: t.team_colour }}
																		className="fa fa-circle"
																	></i>
																	&nbsp;
																	{t.team_name}
																</Badge>
															);
														})}
												</td>
												<td>
													<GameButton game={g} />
													<PresenterButton game={g} />
												</td>
												<td onClick={() => deleteGame(g.game_id)}>
													<i className="fa fa-times"></i>
												</td>
											</tr>
											// <p key={g.game_id}><Link to={'/presenter/' + g.game_id}>
											// 	Started: {g.game_started}<br />
											// 	{g.final_round == "1" && <>FINAL<br /></>}
											// 			Round: {g.round_number}<br />
											// 			Teams: {g.teams.map(t => {
											// 		return (
											// 			<b style={{ backgroundColor: t.team_colour }}>{t.team_name}</b>
											// 		)
											// 	})}
											// </Link></p>
										);
									})}
								</tbody>
							</Table>
							{!event.has_final && (
								<Button
									onClick={() => request("createFinal")}
									className="btn-block"
								>
									Create final game
								</Button>
							)}
							{event.has_final && (
								<Button
									onClick={() => request("topToFinal")}
									className="btn-block"
								>
									Top teams to final
								</Button>
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
			{/* <pre>
				{JSON.stringify(event, null, '\t')}
			</pre> */}
			<PlayersModal
				team_id={playerModalTeam}
				set_team_id={setPlayerModalTeam}
				request={request}
				event={event}
				setEvent={setEvent}
			/>
		</>
	);
};

const TeamPlayerDropdown = (props) => {
	var { person_id, teams, movePersonTeam, renamePlayer } = props;
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(!dropdownOpen);

	return (
		<Dropdown
			isOpen={dropdownOpen}
			toggle={toggle}
			style={{ display: "inline" }}
		>
			<DropdownToggle
				tag="span"
				onClick={toggle}
				size="sm"
				caret
			></DropdownToggle>
			<DropdownMenu>
				{teams.map((t) => {
					return (
						<DropdownItem
							key={person_id + "-" + t.team_id}
							onClick={() => movePersonTeam(person_id, t.team_id)}
						>
							<i className="fa fa-arrow-right"></i> Move to {t.team_name}
						</DropdownItem>
					);
				})}
				<DropdownItem divider></DropdownItem>
				<DropdownItem onClick={() => renamePlayer(person_id)}>
					<i className="fa fa-pencil-alt"></i> Rename Player
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const TeamDropdown = (props) => {
	var { team_id, request, renameTeam } = props;
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(!dropdownOpen);

	var colors = [
		"green",
		"blue",
		"purple",
		"orange",
		"red",
		"pink",
		"yellow",
		"grey",
		"saddlebrown",
		"lightskyblue",
	];

	return (
		<Dropdown
			key={team_id + "dropdown"}
			isOpen={dropdownOpen}
			toggle={toggle}
			style={{ display: "inline" }}
		>
			<DropdownToggle
				tag="span"
				onClick={toggle}
				size="sm"
				caret
			></DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => renameTeam(team_id)}>
					<i className="fa fa-pencil-alt"></i> Rename Team
				</DropdownItem>
				<DropdownItem divider></DropdownItem>
				{colors.map((c) => {
					return (
						<DropdownItem
							key={team_id + c}
							onClick={() => request("colorTeam", { team_id, color: c })}
						>
							<i style={{ color: c }} className="fa fa-circle"></i>{" "}
							{c.charAt(0).toUpperCase() + c.slice(1)}
						</DropdownItem>
					);
				})}
			</DropdownMenu>
		</Dropdown>
	);
};

const RoundDropdown = (props) => {
	var { game_id, request } = props;
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(!dropdownOpen);

	return (
		<Dropdown
			key={game_id + "dropdown"}
			isOpen={dropdownOpen}
			toggle={toggle}
			style={{ display: "inline" }}
		>
			<DropdownToggle
				tag="span"
				onClick={toggle}
				size="sm"
				caret
			></DropdownToggle>
			<DropdownMenu>
				{/* {has_round && <DropdownItem onClick={() => request('changeRound', { round: 0, game_id })}>Remove Round</DropdownItem>} */}
				{[1, 2, 3, 4, 5, 6, 7, 8].map((r) => {
					return (
						<DropdownItem
							key={game_id + r}
							onClick={() => request("changeGameRound", { round: r, game_id })}
						>
							Round {r}
						</DropdownItem>
					);
				})}
			</DropdownMenu>
		</Dropdown>
	);
};

const GameButton = ({ game }) => {
	var joinColour = game.game_started ? "secondary" : "success";
	var joinText = game.final_round ? "Join Final" : "Join Game";
	var gameURL = `https://surveysays.myactivityhub.net/${game.game_url}`;
	var onClick = () => (window.location = gameURL);
	return (
		<Button className="mr-1" size="sm" onClick={onClick} color={joinColour}>
			{" "}
			{joinText}{" "}
		</Button>
	);
};
const PresenterButton = ({ game }) => {
	var joinText = game.final_round ? "Presenter Final" : "Presenter";
	var gameURL = game.final_round
		? `/presenter/final/${game.game_id}`
		: `/presenter/${game.game_id}`;
	var onClick = () => (window.location = gameURL);
	return (
		<Button size="sm" onClick={onClick} color="info">
			{" "}
			{joinText}{" "}
		</Button>
	);
};

export default Event;
